// src/components/LearnList.js
import React, { useEffect, useState } from "react";
import Header from "./header"; // Import the existing Header
import Footer from "./Footer";
import "./BlogsPage.css";

const LearnList = () => {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Log ResizeObserver issues for debugging
    const resizeObserverErrorHandler = () => {
      console.error("ResizeObserver loop error suppressed.");
    };
    window.addEventListener("error", resizeObserverErrorHandler);

    return () => {
      window.removeEventListener("error", resizeObserverErrorHandler);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("Search Query:", searchQuery);
    // Implement search functionality here (e.g., filter or search widget data)
  };

  return (
    <div className="learn-container">
      {/* Header */}
      <Header />

      {/* Search Bar */}
      <form className="search-bar" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <button type="submit" className="search-button">Search</button>
      </form>

      {/* RSS widget */}
      <div className="rss-widget-container">
        <rssapp-wall id="5MgS2LvTkuf64yDQ"></rssapp-wall>
        <script
          src="https://widget.rss.app/v1/wall.js"
          type="text/javascript"
          async
        ></script>
      </div>

      {/* Subscription Button */}
      <a
        className="libutton small"
        href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7241946044966592512"
        target="_blank"
        rel="noopener noreferrer"
      >
        Subscribe on LinkedIn
      </a>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LearnList;
