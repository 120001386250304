import React, { useEffect, useState } from "react";
import "./homepage.css";
import Header from './header'; // Adjust the path based on the location of your Header.js file
import Footer from './Footer'; // Import the Footer component
import Modal from "./Modal"; // Modal component
import ContactForm from "./ContactForm"; // ContactForm component
import { Link } from "react-router-dom";

const scrollToServices = () => {
  const servicesSection = document.getElementById("services");
  if (servicesSection) {
    servicesSection.scrollIntoView({ behavior: "smooth" });
  }
};

const Homepage = () => {
  const [activeTab, setActiveTab] = useState("tech");  // Corrected useState hook initialization
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  
  const [subject, setSubject] = useState('');
  const isSubjectEditable = true; // You can manage this prop dynamically
  useEffect(() => {
    const sections = document.querySelectorAll(".scroll-section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("scroll-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
  const handleModalToggle = (heading, label) => {
    setIsModalOpen(!isModalOpen); // Toggle modal visibility
    setSubject(`${heading}: ${label}`); // Set subject as heading and label with colon separator
  };
  
  return (
    <div>
      <Header /> {/* Add the Header component */}
      <main>
        <div className="homepage">
          {/* Hero Section */}
          <section className="hero">
            <div className="hero-content">
              <div className="hero-text">
                <h1>Empowering Entrepreneurs</h1>
                <p>Legally dedicated to budding entrepreneurs, tech adopters & digital creators.</p>
                <div className="hero-buttons">
                  <button className="primary-button" onClick={scrollToServices}>
                    Explore Services
                  </button>
                </div>
              </div>
            </div>
          </section>


               {/* Action Items Section */}
               <section className="section-below-hero scroll-section">
            <div className="action-cards">
              <div className="action-card-white">
                <h3>Request a Consultation</h3>
                <p>
                  Talk to a legal consultant and get expert legal advice for
                  your startup.
                </p>
                <button
  className="action-card-white-button"
  onClick={() => handleModalToggle("Request", "Consultation")}
>
  Get Started
</button>
            {/* Modal with ContactForm as child */}
            <Modal isOpen={isModalOpen} onClose={handleModalToggle}>
        <ContactForm
          subject={subject}
          setSubject={setSubject}
          isSubjectEditable={isSubjectEditable}
        />
      </Modal>

              </div>
              <div className="action-card-black">
                <h3>Self-Service</h3>
                <p>
                  Use our automated tools to make sound legal business
                  decisions.
                </p>
                <Link to="/teresya">
                  <button className="action-card-black-button">Get Started</button>
                </Link>
              </div>
            </div>
          </section>

      {/* About Us Section */}
      <section className="about scroll-section">
        <h2>About Us</h2>
        <p>
          With over a decade of combined experience across diverse fields, our team provides tailored legal solutions on business and technology. We empower startups, tech adopters, and digital creators through international and jurisdiction-specific legal guidance and tools.
        </p>
        <Link to="/AboutUs">
          <button className="learn-more">
            Learn More <span className="arrow">→</span>
          </button>
        </Link>
      </section>


{/* Services Section */}
<section id="services" className="services scroll-section">
  <h2>Specialties</h2>
  <div className="service-cards">
    {/* Service Card - Business Incorporation */}
    <div className="service-card" id="business-incorporation">
      <h3>Business Incorporation</h3>
      <p>Guided decisions in corporate structuring, business registration, licensing, and permits.</p>
      <a href="/servicePage#servicesExtend">Learn More</a>
    </div>
    <div className="service-card" id="corporate-structuring">
      <h3>Corporate Structuring</h3>
      <p>Tailored legal support in structuring your corporate entities for growth and scalability.</p>
      <a href="/servicePage#corporate-structuring">Learn More</a>
    </div>
    <div className="service-card" id="ip-protection">
      <h3>IP Protection</h3>
      <p>Make the most out of your intellectual property through legal protections and commercialization.</p>
      <a href="/servicePage#ip-protection">Learn More</a>
    </div>
    <div className="service-card" id="tech-compliance">
      <h3>Tech Compliance</h3>
      <p>Stay secure and compliant with services for tech development.</p>
      <a href="/servicePage#tech-compliance">Learn More</a>
    </div>
    <div className="service-card" id="digital-footprint-management">
      <h3>Digital Footprint Management</h3>
      <p>Optimize and manage your business’s digital presence and compliance.</p>
      <a href="/servicePage#digital-footprint-management">Learn More</a>
    </div>
    <div className="service-card" id="contracts-partnerships">
      <h3>Contracts & Partnerships</h3>
      <p>Receive contract review and negotiation guidance.</p>
      <a href="/servicePage#contracts-partnerships">Learn More</a>
    </div>
    <div className="service-card" id="data-privacy">
      <h3>Data Privacy</h3>
      <p>Ensure compliance with data privacy laws and cross-border transfer regulations.</p>
      <a href="/servicePage#data-privacy">Learn More</a>
    </div>
    <div className="service-card" id="investments-due-diligence">
      <h3>Investments & Due Diligence</h3>
      <p>Make informed decisions on secure and favorable investment structures.</p>
      <a href="/servicePage#investments-due-diligence">Learn More</a>
    </div>
  </div>
  <div className="service-button">
    <Link to="/servicePage">
      <button className="primary-button">
        More Services
      </button>
    </Link>
  </div>
</section>




{/* Client Profiles Section */}
<section id="client-profiles" className="industries-section">
  <h2>Popular Client Profiles</h2>
  <div className="tabs">
    <button
      className={`tab ${activeTab === "entrepreneurs" ? "active" : ""}`}
      onClick={() => setActiveTab("entrepreneurs")}
    >
      Entrepreneurs
    </button>
    <button
      className={`tab ${activeTab === "digital-creators" ? "active" : ""}`}
      onClick={() => setActiveTab("digital-creators")}
    >
      Digital Creators
    </button>
    <button
      className={`tab ${activeTab === "tech-adopters" ? "active" : ""}`}
      onClick={() => setActiveTab("tech-adopters")}
    >
      Tech Adopters
    </button>
    <button
      className={`tab ${activeTab === "investors" ? "active" : ""}`}
      onClick={() => setActiveTab("investors")}
    >
      Investors
    </button>
  </div>
  <div className="tab-content">
    {activeTab === "entrepreneurs" && (
      <p>
        We support entrepreneurs by providing legal solutions for business formation, contracts, intellectual property, and more.
      </p>
    )}
    {activeTab === "digital-creators" && (
      <p>
        Digital creators receive legal advice on copyrights, content protection, and partnership agreements to thrive in the digital world.
      </p>
    )}
    {activeTab === "tech-adopters" && (
      <p>
        We offer guidance to tech adopters in navigating legal aspects of adopting new technologies and innovations within their business.
      </p>
    )}
    {activeTab === "investors" && (
      <p>
        Investors benefit from our services, ensuring compliance and legal protection in their investments and ventures.
      </p>
    )}
  </div>
  
  {/* Free Consultation Section */}
  <div className="free-consultation-card">
    <h3>Ready to Transform Your Business?</h3>
    <p>Leverage legal expertise to protect and grow your business.</p>
    <button
  className="action-card-white-button"
  onClick={() => handleModalToggle("Schedule", "Discovery")}
>
  Schedule a Discovery
</button>

            {/* Modal with ContactForm as child */}
            <Modal isOpen={isModalOpen} onClose={handleModalToggle}>
        <ContactForm
          subject={subject}
          setSubject={setSubject}
          isSubjectEditable={isSubjectEditable}
        />
      </Modal>

  </div>
</section>


{/* Client Testimonials Section */}
<section id="client-testimonials" className="testimonials-section">
  <h2>Client Testimonials</h2>
  <div className="testimonials-wrapper">
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Dec 9, 2024</p>
      <h3>Business Incorporation</h3>
      <p className="testimonial-category">Business Legal Support</p>
      <p className="testimonial-author">Juan Diego Rodriguez</p>
      <p>"Their guidance in corporate structuring and business registration helped us set up a solid foundation. They provided clear, actionable advice on licensing and permits that made a huge difference."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Dec 4, 2024</p>
      <h3>IP Protection</h3>
      <p className="testimonial-category">Legal Strategy</p>
      <p className="testimonial-author">Geoff Graham</p>
      <p>"The legal advice we received on intellectual property protection was invaluable. Their expertise helped us maximize our IP’s potential while ensuring it was well protected for the future."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Dec 12, 2024</p>
      <h3>Tech Compliance</h3>
      <p className="testimonial-category">Legal Strategy | Content Innovation</p>
      <p className="testimonial-author">Daniel Schwarz</p>
      <p>"Navigating tech compliance felt overwhelming, but their strategic advice gave us the clarity we needed. With their help, we are confident in our compliance with industry regulations."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Oct 29, 2024</p>
      <h3>International Expansion</h3>
      <p className="testimonial-category">Legal Solutions | Funding</p>
      <p className="testimonial-author">Sara Joy</p>
      <p>"Expanding globally comes with its challenges, but their expert legal support made the process much smoother. We could navigate complex legal landscapes with ease."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Oct 29, 2024</p>
      <h3>Contracts & Partnerships</h3>
      <p className="testimonial-category">Legal Strategy | Growth</p>
      <p className="testimonial-author">Zell Liew</p>
      <p>"Their advice on contract negotiations was pivotal in securing strong partnerships. They helped us protect our interests while also facilitating a fair and clear agreement."</p>
    </div>
  </div>
</section>

<section class="trending-topics">
<div class="shape shape-one"></div>
<div class="shape shape-two"></div>
  <h2>Trending topics</h2>
  <a href="/BlogsPage" class="more-topics">MORE TRENDING TOPICS →</a>

  <div class="topic-container">
    <div class="topic">
      <h3>Generative AI</h3>
    </div>
    <div class="topic">
      <h3>Environmental, Social & Governance</h3>
    </div>
    <div class="topic">
      <h3>AdTech</h3>
    </div>
  </div>

  <div class="stay-updated">
    <h3>Stay Updated</h3>
    <p>Subscribe to get the latest updates directly in your inbox:</p>
    <form class="email-subscription">
      <input 
        type="email" 
        placeholder="Enter your email" 
        aria-label="Enter your email to stay updated" 
        required 
      />
      <button type="submit">Subscribe</button>
    </form>
  </div>
</section>

          <Footer /> {/* Add the Footer component */}
        </div>
      </main>
    </div>
  );
};

export default Homepage;
