import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import routing components
import Homepage from "./homepage";
import TeresyaPage from "./teresya"; // Import TeresyaPage
import "./App.css";
import AboutUs from "./AboutUs"; // Import TeresyaPage
import ContactUs from './ContactUs';
import ServicePage from './ServicePage';
import PrivacyPolicy from './privacy';
import LearnList from './BlogsPage';

if (process.env.NODE_ENV === 'development') {
  // Suppress ResizeObserver errors in development
  window.addEventListener('error', (e) => {
    if (e.message.includes('ResizeObserver loop')) {
      e.preventDefault();
    }
  });
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Route for Homepage */}
          <Route path="/" element={<Homepage />} />

          {/* Route for TeresyaPage */}
          <Route path="/teresya" element={<TeresyaPage />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/ServicePage" element={<ServicePage />} />      
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/BlogsPage" element={<LearnList />} />      

        </Routes>
      </div>
    </Router>
  );
}

export default App;
