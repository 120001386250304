import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import the EmailJS SDK
import "./ContactForm.css";

const ContactForm = ({ subject, setSubject, isSubjectEditable, closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [company, setCompany] = useState('');
  const [emailSent, setEmailSent] = useState(false); // Track email sent status

  const sendEmail = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    const templateParams = {
      subject,
      name,
      email,
      message,
      company
    };

    emailjs.send('service_ohp6vf9', 'template_cblkn58', templateParams, 'inqqXvUoU7veLS_S-')
      .then((response) => {
        console.log('Email sent successfully', response);
        setEmailSent(true);  // Set emailSent state to true after successful email send
        setName('');
        setEmail('');
        setMessage('');
        setCompany('');
        setTimeout(() => {
          setEmailSent(false);
          closeModal(); // Close modal after 3 seconds
        }, 3000); // Hide success message and close modal after 3 seconds
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('There was an error sending your message. Please try again later.');
      });
  };

  return (
    <div className="contact-form-container">
      <h2 className="contact-form-title">We are all ears:</h2>
      <form onSubmit={sendEmail} className="contact-form">
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          disabled={!isSubjectEditable} // Subject editable based on prop
        />
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          name="company"
          placeholder="Your Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button type="submit" className="primary-button">Submit</button>
      </form>
      {emailSent && (
        <div className="success-overlay">
          <p className="success-message">Email sent successfully!</p>
          <i className="fa fa-check-circle success-icon"></i> {/* Example icon */}
        </div>
      )}
    </div>
  );
};

export default ContactForm;

