import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // To handle navigation
import { FaLinkedin } from 'react-icons/fa'; // For LinkedIn icon
import { FaVolumeUp } from 'react-icons/fa'; // For the speaker icon
import Header from './header'; // Adjust the path based on the location of your Header.js file
import Footer from './Footer'; // Import the Footer component

const teresyaImage = `${process.env.PUBLIC_URL}/teresya.jpeg`;
const qrImage = `${process.env.PUBLIC_URL}/qr.webp`; // Add your QR code image path here
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    background: 'linear-gradient(to right, #ffffff, #a8e6cf)', // White to Green gradient background
    color: '#333',
    textAlign: 'center',
    padding: '30px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontFamily: 'Urbanist, sans-serif',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    borderRadius: '0 0 0px 0px', // Rounded bottom corners
    overflow: 'hidden',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '20px',
    maxWidth: '500px',
    marginTop: '70px',
  },
  title: {
    fontSize: '5rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '15px',
    letterSpacing: '1.5px',
  },
  description: {
    fontSize: '1.2rem',
    color: '#555',
    margin: '10px 0',
    textAlign: 'left',
    fontWeight: '300',
    maxWidth: '400px',
  },
  comingSoonText: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: 'rgba(150, 178, 61, 0.6)',
    backgroundColor: 'transparent',
    padding: '15px 30px',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '3px',
    marginBottom: '30px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  button: {
    padding: '12px 30px',
    fontSize: '1.2rem',
    backgroundColor: 'rgb(150, 178, 61)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 0.3s ease, transform 0.2s ease-in-out',
    marginTop: '20px',
    letterSpacing: '2px',
  },
  buttonHover: {
    backgroundColor: '#3a5c22',
    transform: 'scale(1.1)',
  },
  linkedInSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  linkedInIcon: {
    fontSize: '2rem',
    color: '#0077b5',
    margin: '0 10px',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  linkedInIconHover: {
    color: '#005582',
  },
  speakerIcon: {
    fontSize: '1.5rem',
    color: '#333',
    cursor: 'pointer',
    marginLeft: '10px',
  },
};

const animations = `
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .pageContainer {
    flex-direction: column;
    padding: 20px;
  }
  .title {
    font-size: 3rem;
  }
  .comingSoonText {
    font-size: 1.5rem;
  }
  .image {
    width: 90%;
    margin-bottom: 20px;
    height: auto;
  }
  .textContainer {
    maxWidth: 100%;
    marginTop: 20px;
    paddingLeft: 0;
  }
}
`;

function TeresyaPage() {
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;

    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      setVoices(availableVoices);
      const emilyVoice = availableVoices.find(
        (voice) => voice.name === 'Microsoft Emily Online (Natural) - English (Ireland)'
      );
      setSelectedVoice(emilyVoice || availableVoices[0]);
    };

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }

    loadVoices();
  }, []);

  const speakText = () => {
    if (!selectedVoice) return;
    const utterance = new SpeechSynthesisUtterance('Tasyah');
    utterance.voice = selectedVoice;
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div style={styles.container}>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600&display=swap');
        `}
      </style>
      <Header />
      <main style={styles.pageContainer}>
        <div style={styles.imageContainer}>
          <img src={teresyaImage} alt="Teresya Logo" style={styles.image} />
        </div>
        <div style={styles.textContainer}>
          <h1 style={styles.title}>
            Terésya
            <FaVolumeUp style={styles.speakerIcon} onClick={speakText} />
          </h1>
          <p style={styles.description}>
            Hi I'm a business legal companion. I help simplify entrepreneurial decisions for startups globally.
          </p>
          <p style={styles.comingSoonText}>Coming Soon</p>
          <Link to="/subscribe" style={{ textDecoration: 'none' }}>
            <div>
              <img src={qrImage} alt="QR Code" style={{ width: '70px', height: '70px' }} />
              <div>
                <span style={{ marginRight: '20px', fontSize: '1rem', color: '#333' }}>
                  Scan here for more
                </span>
              </div>
            </div>
            <button
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
            >
              I'm Interested
            </button>
          </Link>
          <div style={styles.linkedInSection}>
            <span style={{ marginRight: '10px', fontSize: '1rem', color: '#333' }}>
              Join our community of reviewers
            </span>
            <a
              href="https://www.linkedin.com/showcase/teresya/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                style={styles.linkedInIcon}
                onMouseOver={(e) => (e.target.style.color = styles.linkedInIconHover.color)}
                onMouseOut={(e) => (e.target.style.color = styles.linkedInIcon.color)}
              />
            </a>
          </div>
        </div>
      </main>
      <Footer />
      <style>{animations}</style>
    </div>
  );
}

export default TeresyaPage;
