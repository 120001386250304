// Header.js
import React, { useState, useEffect } from 'react';
import './header.css';

function Header() {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide header on scroll up
      } else {
        setIsVisible(true); // Show header on scroll down
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <header className={`header ${isVisible ? 'visible' : 'hidden'}`}>
<div className="logo">
  <img src="/logo.webp" alt="ELP Logo" />
  <span>Entrepreneurial Legal Partner</span>
</div>

      <nav className="nav-links">
      <a href="/">Home</a>
        <a href="/ServicePage">Services</a>
        <a href="/teresya">Tools</a> {/* Updated Tools link */}
        <a href="/ContactUs">Contact Us</a>
        <a href="/BlogsPage">Insights</a>

      </nav>
    </header>
  );
}

export default Header;
