import React from 'react';
import './PrivacyPolicy.css'; // Add the necessary CSS styling for the page

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 11 September 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Entrepreneurial Legal Partner (ELP). We are committed to protecting your personal information and ensuring your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you visit our website <a href="http://www.entrepreneuriallegalpartner.com">www.entrepreneuriallegalpartner.com</a> or use our services.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <h3>2.1 Personal Information</h3>
        <p>We may collect personal information such as your name, email address, phone number, and any other details you provide to us.</p>

        <h3>2.2 Usage Data</h3>
        <p>We may collect information about how you interact with our website, including your IP address, browser type, pages visited, and the time and date of your visits.</p>

        <h3>2.3 Cookies</h3>
        <p>
          We use cookies and similar technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us understand your preferences and improve our services.
        </p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>To provide and improve our services</li>
          <li>To communicate with you, including sending updates, newsletters, and promotional materials</li>
          <li>To respond to your inquiries and support requests</li>
          <li>To analyze website usage and trends</li>
          <li>To ensure the security and integrity of our website and services</li>
        </ul>
      </section>

      <section>
        <h2>4. Sharing Your Information</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
        <ul>
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website and delivering our services.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights, privacy, safety, or property, or that of others.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</li>
        </ul>
      </section>

      <section>
        <h2>5. Data Security</h2>
        <p>
          We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any concerns about how we handle your data, please contact us at <a href="mailto:entr.legal.partner@gmail.com">entr.legal.partner@gmail.com</a>.
        </p>
      </section>

      <section>
        <h2>7. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.
        </p>
      </section>

      <section>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any updates.
        </p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <address>
          Entrepreneurial Legal Partner <br />
          Email: <a href="mailto:entr.legal.partner@gmail.com">entr.legal.partner@gmail.com</a><br />
          Website: <a href="http://www.entrepreneuriallegalpartner.com">www.entrepreneuriallegalpartner.com</a>
        </address>
      </section>

      <p><strong>I have read and agree to the Privacy Policy</strong></p>
    </div>
  );
};

export default PrivacyPolicy;
