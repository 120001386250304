import React, { useState } from 'react';
import './ContactUs.css';
import Header from './header';
import Footer from './Footer';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import emailjs from 'emailjs-com'; // Import EmailJS

const ContactUs = () => {
  const [formData, setFormData] = useState({
    subject: '',
    name: '',
    email: '',
    message: ''
  });

  const [messageStatus, setMessageStatus] = useState(''); // State to hold success/error messages
  const [formDisabled, setFormDisabled] = useState(false); // To disable form while sending

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    setFormDisabled(true); // Disable form while the request is sent

    emailjs.send('service_ohp6vf9', 'template_cblkn58', templateParams, 'inqqXvUoU7veLS_S-')
      .then(() => {
        setMessageStatus('Your message has been sent successfully!');
        setTimeout(() => {
          setMessageStatus('');
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        }, 5000); // Reset form after success message
      })
      .catch(() => {
        setMessageStatus('Failed to send your message. Please try again.');
        setTimeout(() => {
          setMessageStatus('');
        }, 5000); // Clear error message after 5 seconds
      })
      .finally(() => {
        setFormDisabled(false); // Re-enable form after processing
      });
  };

  return (
    <div>
      <Header />
      <main>


        <section className="contact-section">
          <div className="contact-left">
            <h1 className="contact-title">Let's chat.<br />Tell us about your business.</h1>
            <p className="contact-description">Let's secure together.</p>
          </div>
          <div className="contact-right">
            <h2 className="contact-form-title">Send us a message</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full name*"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email address*"
                required
              />
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us more about your project*"
                required
              ></textarea>
              <button type="submit" className="contact-submit" disabled={formDisabled}>Send message</button>
            </form>
            {messageStatus && <p className="contact-message">{messageStatus}</p>} {/* Display success/error message */}
          </div>
        </section>
        <section className="contact-options-section">
        <div>
        <h1 className="contact-heading">Email</h1>
        <p className="contact-desc">info@entrepreneuriallegalpartner.com</p>


            </div>
          <h1 className="contact-heading">Connect</h1>
          <div className="social-media-icons">
            <a href="https://www.linkedin.com/company/entrepreneuriallegalparner/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={30} />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={30} />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={30} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
          </div>
 

        </section>

      </main>
      <Footer />
    </div>
  );
};

export default ContactUs;
